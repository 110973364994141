import React, { memo } from "react";

//router
import { Switch, Redirect } from "react-router";

//layout pages
import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

const userRoutes = [
  { path: "/dashboard", component: Default },
  { path: "/", component: () => <Redirect to="/dashboard" /> },
  { path: "/staff-dashboard", component: Default },
  { path: "/students/:currentPage/:active_tab?", component: Default },
  { path: "/student/:studentID/course/:id/:active_tab?", component: Default },
  { path: "/student/add", component: Default },
  { path: "/student/:studentID/:currentPage/update", component: Default },
  { path: "/student/:studentID/:currentPage/:active_tab?", component: Default },
  { path: "/student-reports", component: Default },
  { path: "/teachers/:active_tab?", component: Default },
  { path: "/teacher-reports", component: Default },
  { path: "/crm/:active_tab?", component: Default },
  { path: "/search", component: Default },

  { path: "/campaign", component: Default },

  { path: "/leads-details/:id", component: Default },
  { path: "/teacher/edit/:id", component: Default },
  { path: "/unassigned-students/:id", component: Default },
  { path: "/reassign-student/:id", component: Default },
  { path: "/teacher-demoCourse", component: Default },
  { path: "/teacher-badgeTier", component: Default },

  { path: "/unassigned-teacher/:teacherID", component: Default },
  { path: "/reassign-teacher/:teacherID", component: Default },
  { path: "/teacher/add", component: Default },
  { path: "/teacher/:teacherID/:user/:active_tab?", component: Default },
  { path: "/teacher-withdrawals", component: Default },
  { path: "/teacher-trainings-list", component: Default },
  { path: "/teacher-training-details/:id", component: Default },
  { path: "/staff/:staffType/:staffID/:user/:active_tab?", component: Default },
  { path: "/requests/:active_tab?", component: Default },
  { path: "/requests/:active_tab/:requestID/details", component: Default },
  { path: "/customer-support/:active_tab?", component: Default },
  { path: "/customer-support/Students/:studentID/details", component: Default },
  { path: "/customer-support/Teacher/:teacherID/details", component: Default },
  { path: "/staffs/:active_tab?", component: Default },
  { path: "/staff-reports", component: Default },
  { path: "/add-staff", component: Default },
  { path: "/edit-staff/:id", component: Default },
  { path: "/courses", component: Default },
  { path: "/course/add", component: Default },
  { path: "/course/details/:id/:active_tab?", component: Default },
  { path: "/course/edit/:id", component: Default },
  { path: "/chat", component: Default },

  { path: "/contact-support", component: Default },
  { path: "/contact-support/:supportID/details", component: Default },

  { path: "/enrollment/tuition-request", component: Default },
  { path: "/tuition/add-tuition", component: Default },
  { path: "/tuition/update-tuition", component: Default },

  { path: "/enrollment/course-list", component: Default },
  { path: "/enrollment/tuition/:id/create", component: Default },
  { path: "/enrollment/tuition-request/:id/create-new", component: Default },
  { path: "/enrollment/demo-class", component: Default },
  { path: "/enrollments/add-course", component: Default },
  { path: "/enrollments/update-course", component: Default },
  { path: "/enrollments/add-demo-class", component: Default },
  { path: "/enrollments/update-demo-class", component: Default },
  { path: "/enrollments/update-demo-class", component: Default },
  { path: "/enrollments/tuition", component: Default },
  { path: "/enrollments/tuition/:id/plan/:planID", component: Default },
  { path: "/enrollment/tuition-request/:status/:id/:active_tab?", component: Default },

  { path: "/payroll/:active_tab?", component: Default },

  { path: "/payroll/staff/:staffID/details", component: Default },
  { path: "/payroll/Teacher/:teacherID/details", component: Default },
  { path: "/reports/:active_tab?", component: Default },
  { path: "/profile/:active_tab?", component: Default },

  { path: "/attendance/calendar", component: Default },
  { path: "/users", component: Default },

  { path: "/settings/generalsettings/:active_tab?", component: Default },
  { path: "/settings/studsettings/:active_tab?", component: Default },
  { path: "/settings/professor/:active_tab?", component: Default },
  { path: "/settings/workforce/:active_tab?", component: Default },
  { path: "/settings/payment/:active_tab?", component: Default },
  { path: "/settings/payment/:active_tab?/:categoryID/subcategory", component: Default,},


  { path: "/cluster/:active_tab?", component: Default,},
  { path: "/clusters/add", component: Default,},
  { path: "/clusters/:clusterId/:active_tab?", component: Default },
  { path: "/Cluster/:clusterId/batches/:batchesId/details", component: Default },
  { path: "/Cluster/:clusterId/timetable/:timetableId/details", component: Default },
  { path: "/Cluster/:clusterId/package/:packageId/details", component: Default },

  { path: "/master-cluster/:active_tab?", component: Default,},
  { path: "/master-clusters/add", component: Default,},
  { path: "/master-cluster/:teacherId/:masterClusterId/:active_tab?", component: Default },
  { path: "/master-cluster/:masterClusterId/batches/:batchesId/:teacherId/details", component: Default },
  { path: "/master-cluster/:masterClusterId/timetable/:timetableId/:teacherId/details", component: Default },
  { path: "/master-cluster/:masterClusterId/package/:packageId/:teacherId/details", component: Default },

  { path: "/referral", component: Default,},

  { path: "/transactions/:active_tab?", component: Default },
  { path: "/transaction/addincome", component: Default },
  { path: "/transaction/editincome/:transactionID", component: Default },
  { path: "/transaction/addexpense", component: Default },
  { path: "/transaction/editexpense/:transactionID", component: Default },
  { path: "/transaction-report", component: Default },

  { path: "/country/:countryID/states/", component: Default },
  { path: "/state/:stateID/districts/", component: Default },

  { path: "/notifications", component: Default },

  //Error Pages
  { path: "/errors/error404", component: Simple },
  
];

const authRoutes = [
  { path: "/danger/delete-account", component: Simple },
  { path: "/account/delete-success", component: Simple },
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
  { path: "/privacy-policy", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
