import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Loading1 from "../components/custom/loading1";

const AdminDashboard = lazy(() => import("../views/Dashboard/AdminDashboard2"));
const StaffDashboard = lazy(() => import("../views/Dashboard/StaffDashboard"));

const Students = lazy(() => import("../views/Students/Index"));
const AddStudent = lazy(() => import("../views/Students/AddStudent"));
const UpdateStudent = lazy(() => import("../views/Students/UpdateStudent"));
const StudentDashboard = lazy(() =>import("../views/Students/StudentDashboard/Index"));

const StudentCourseDetails = lazy(() =>import("../views/Students/StudentDashboard/Courses/CourseDetails"));
const StudentReports = lazy(() => import("../views/Students/Reports/index"));
const AssignStudent = lazy(() => import("../views/AssignStudent/Assign"));
const ReassignStudent = lazy(() => import("../views/AssignStudent/Reassign"));

const Crm = lazy(() => import("../views/Crm/Index"));
const Campaign = lazy(() => import("../views/Crm/Campaign/Index"));

const AssignTeacher = lazy(() => import("../views/AssignTeacher/Assign"));
const ReassignTeacher = lazy(() => import("../views/AssignTeacher/Reassign"));
const Teachers = lazy(() => import("../views/Teachers/Index"));
const TeacherReports = lazy(() => import("../views/Teachers/Reports/index"));

const AddTeacher = lazy(() =>import("../views/Teachers/TeachersList/AddTeacher"));
const TeacherDashboard = lazy(() =>import("../views/Teachers/TeacherDashboard/Index"));
const StaffDetailsDashboard = lazy(() =>import("../views/Staffs/StaffDetailsDashBoard/Index"));
const EditTeacher = lazy(() => import("../views/Teachers/TeachersList/EditTeacher"));

const TeacherRequests = lazy(() => import("../views/TeacherRequestsAll/TeacherRequests/Index"));
const TeacherDemoCourse = lazy(() =>import("../views/TeacherRequestsAll/TeacherDemoCourse/Index"));
const TeacherBadgeTier = lazy(() => import("../views/TeacherRequestsAll/TeacherBadgeList/Index"));

const CustomerSupport = lazy(() => import("../views/CustomerSupport/Index"));

const TeacherSupportDetails = lazy(() =>import("../views/CustomerSupport/Teacher/Details"));
const StudentSupportDetails = lazy(() =>import("../views/CustomerSupport/Students/Details"));


const Staff = lazy(() => import("../views/Staffs/index"));
const StaffReports = lazy(() => import("../views/Staffs/Reports/index"));
const AddStaff = lazy(() => import("../views/Staffs/AddStaff"));
const EditStaff = lazy(() => import("../views/Staffs/EditStaff"));

const Payroll = lazy(() => import("../views/Payroll/Index"));
const StaffPayrollDetails = lazy(() =>import("../views/Payroll/Staff/Details"));
const TeacherPayrollDetails = lazy(() =>import("../views/Payroll/Teacher/Details"));

const GeneralSettings = lazy(() =>import("../views/Settings/GeneralSettings/GeneralSettings"));
const States = lazy(() =>import("../views/Settings/GeneralSettings/States/StatesList"));
const Districts = lazy(() =>import("../views/Settings/GeneralSettings/Districts/DistrictList"));
const StudentSettings = lazy(() =>import("../views/Settings/StudentSettings/StudentSettings"));
const TeacherSettings = lazy(() =>import("../views/Settings/Teachersettings/TeacherSettings"));
const StaffSettings = lazy(() =>import("../views/Settings/StaffSettings/StaffSettings"));
const TransactionSettings = lazy(() =>import("../views/Settings/TransactionSettings/TransactionSettings"));

const ContactSupport = lazy(() => import("../views/Support/index"));
const SupportDetails = lazy(() =>import("../views/Support/SupportDashboard/SupportDetails"));

const Reports = lazy(() => import("../views/Reports/Index"));
const Attendance = lazy(() => import("../views/Profile/Attendance"));

const Course = lazy(() => import("../views/Courses/index"));
const AddCourse = lazy(() => import("../views/Courses/AddCourse"));
const CourseDetails = lazy(() =>import("../views/Courses/CourseDetails/index"));
const EditCourse = lazy(() => import("../views/Courses/EditCourse"));

const newChat = lazy(() => import("../views/Chat/NewChat"));

const TuitionList = lazy(() =>import("../views/Enrollment/TuitionRequest/TuitionList"));
const AddTuition = lazy(() =>import("../views/Enrollment/TuitionRequest/AddTuition"));
const CreateTuition = lazy(() =>import("../views/Enrollment/TuitionRequest/CreateTuition"));
const UpdateTuition = lazy(() =>import("../views/Enrollment/TuitionRequest/UpdateTuition"));
const TuitionRequestDashboard = lazy(() =>import( "../views/Enrollment/TuitionRequest/TutionRequestDetails/TuitionRequestDashboard"));

const CourseList = lazy(() => import("../views/Enrollment/Course/CourseList"));

const DemoClass = lazy(() => import("../views/Enrollment/Class/DemoClass"));

const AddCourse2 = lazy(() => import("../views/Enrollment/Course/AddCourse"));
const UpdateCourse2 = lazy(() =>import("../views/Enrollment/Course/UpdateCourse"));
const AddDemoClass = lazy(() =>import("../views/Enrollment/Class/AddDemoClass"));

const UpdateDemoClass = lazy(() =>import("../views/Enrollment/Class/UpdateDemoClass"));
const Tuition = lazy(() => import("../views/Enrollment/Tuition/Index"));

const TeacherTraining = lazy(() =>import("../views/TeacherRequestsAll/TrainingRequest/TeacherTrainingRequestList"));
const TeacherWithdrawals =lazy(() =>import("../views/TeacherRequestsAll/WithdrawalRequest/Index"));

const TeacherTrainingRequestDetail = lazy(() =>import("../views/TeacherRequestsAll/TrainingRequest/TeacherTrainingRequestDetails"));

const Transactions = lazy(() => import("../views/Transactions/index"));
const AddIncome = lazy(() => import("../views/Transactions/Income/AddIncome"));
const EditIncome = lazy(() =>import("../views/Transactions/Income/EditIncome"));
const AddExpense = lazy(() =>import("../views/Transactions/Expense/AddExpense"));
const EditExpense = lazy(() =>import("../views/Transactions/Expense/EditExpense"));
const SubCategoriesList = lazy(() =>import("../views/Settings/TransactionSettings/SubCategories/SubCategoriesList"));
const TransactionReport = lazy(() => import("../views/Transactions/Report/Index"));

const Cluster = lazy(() => import("../views/Cluster/Index"));
const AddCluster = lazy(() => import("../views/Cluster/AddCluster"));
const ClusterDashboard = lazy(() => import("../views/Cluster/SubGroup/Index"));
const ClusterBatchesDetails = lazy(() => import("../views/Cluster/SubGroup/Batches/ClusterBatchDetails"));
const ClusterTimetableDetails = lazy(() => import("../views/Cluster/SubGroup/Timetable/ClusterTimetableDetails"));
const ClusterPackageDetails = lazy(() => import("../views/Cluster/SubGroup/package/ClusterPackageDetails"));

const MasterCluster = lazy(() => import("../views/MasterCluster/Index"));
const AddMasterCluster = lazy(() => import("../views/MasterCluster/AddMasterCluster"));
const MasterClusterDashboard = lazy(() => import("../views/MasterCluster/SubGroup/Index"));
const MasterClusterBatchesDetails = lazy(() => import("../views/MasterCluster/SubGroup/Batches/MasterClusterBatchDetails"));
const MasterClusterTimetableDetails = lazy(() => import("../views/MasterCluster/SubGroup/Timetable/MasterClusterTimetableDetails"));
const MasterClusterPackageDetails = lazy(() => import("../views/MasterCluster/SubGroup/package/MasterClusterPackageDetails"));

const Referral = lazy(() => import("../views/Referral/Referral"));

const LeadsDetails = lazy(() => import("../views/Crm/LeadsList/LeadsDetails"));

const Profile = lazy(() => import("../views/Profile/Index"));

const GlobalSearch = lazy(() => import("../views//Global/Search"));

const Users = lazy(() => import("../views/Users/UserList"));

const Notifications = lazy(() => import("../views/Notifications/Notifications"));


const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={100}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            {/* Dashboard Routes */}
            <Route path="/dashboard" exact component={AdminDashboard} />
            <Route path="/staff-dashboard" exact component={StaffDashboard} />

            {/* Search Route */}
            <Route path="/search" exact component={GlobalSearch} />

            {/* Student Routes */}
            <Route path="/students/:currentPage/:active_tab?" component={Students} />
            <Route path="/student/add" exact component={AddStudent} />
            <Route path="/student/:studentID/:currentPage/update" exact component={UpdateStudent}/>
            <Route path="/student/:studentID/:currentPage/:active_tab?" exact component={StudentDashboard} />
            <Route path="/student/:studentID/course/:id/:active_tab?" exact component={StudentCourseDetails}/>
            <Route path="/student-reports" exact component={StudentReports} />
            <Route path="/unassigned-students/:id" exact component={AssignStudent}/>
            <Route path="/reassign-student/:id" exact component={ReassignStudent}/>

            {/* Teacher Routes */}
            <Route path="/unassigned-teacher/:teacherID" exact component={AssignTeacher}/>
            <Route path="/reassign-teacher/:teacherID" exact component={ReassignTeacher}/>
            <Route path="/teachers/:active_tab?" exact component={Teachers} />
            <Route path="/teacher-withdrawals" exact component={TeacherWithdrawals} />
            <Route path="/teacher-trainings-list" exact component={TeacherTraining}/>
            <Route path="/teacher-training-details/:id" exact component={TeacherTrainingRequestDetail}/>
            <Route path="/teacher-reports" exact component={TeacherReports} />
            <Route path="/teacher/add" exact component={AddTeacher} />
            <Route path="/teacher/edit/:id" exact component={EditTeacher} />
            <Route path="/teacher/:teacherID/:user/:active_tab?" exact component={TeacherDashboard}/>
            <Route path="/teacher-demoCourse" exact component={TeacherDemoCourse}/>
            <Route path="/requests/:active_tab?"  exact component={TeacherRequests}/>
            <Route path="/teacher-badgeTier" exact component={TeacherBadgeTier}/>

            {/* CRM Routes */}
            <Route path="/crm/:active_tab?" exact component={Crm} />
            <Route path="/leads-details/:id" exact component={LeadsDetails} />
            <Route path="/customer-support/:active_tab?" exact component={CustomerSupport}/>
            <Route path="/customer-support/Students/:studentID/details" exact component={StudentSupportDetails}/>
            <Route path="/customer-support/Teacher/:teacherID/details" exact component={TeacherSupportDetails}/>
            <Route path="/chat" exact component={newChat} />

            <Route path="/campaign" exact component={Campaign} />

            {/* Course Routes */}
            <Route path="/courses" exact component={Course} />
            <Route path="/course/add" exact component={AddCourse} />
            <Route path="/course/edit/:id" exact component={EditCourse} />
            <Route path="/course/details/:id/:active_tab?" exact component={CourseDetails}/>

            {/* Staff Routes */}
            <Route path="/staff/:staffType/:staffID/:user/:active_tab?" exact component={StaffDetailsDashboard}/>
            <Route path="/staffs/:active_tab?" exact component={Staff} />
            <Route path="/staff-reports" exact component={StaffReports} />
            <Route path="/add-staff" exact component={AddStaff} />
            <Route path="/edit-staff/:id" exact component={EditStaff} />
            <Route path="/profile/:active_tab?" exact component={Profile} />
            <Route path="/payroll/:active_tab?" exact component={Payroll} />
            <Route path="/payroll/staff/:staffID/details" exact component={StaffPayrollDetails}/>
            <Route path="/payroll/Teacher/:teacherID/details" exact component={TeacherPayrollDetails}/>

            {/* Report Routes */}
            <Route path="/reports/:active_tab?" exact component={Reports} />
            <Route path="/attendance/calendar" exact component={Attendance} />

            {/* Setting Routes */}
            <Route path="/settings/generalsettings/:active_tab?" exact component={GeneralSettings}/>
            <Route path="/country/:countryID/states" exact component={States} />
            <Route path="/state/:stateID/districts" exact component={Districts}/>
            <Route path="/settings/studsettings/:active_tab?" exact component={StudentSettings}/>
            <Route path="/settings/professor/:active_tab?" exact component={TeacherSettings}/>
            <Route path="/settings/workforce/:active_tab?" exact component={StaffSettings}/>
            <Route path="/settings/payment/:active_tab?" exact component={TransactionSettings}/>
            <Route path="/settings/payment/:active_tab?/:categoryID/subcategory" exact component={SubCategoriesList}/>

            {/* Support Routes */}
            <Route path="/contact-support" exact component={ContactSupport} />
            <Route path="/contact-support/:supportID/details" exact component={SupportDetails}/>

            {/* Enrollment Routes */}
            <Route path="/enrollment/tuition-request" exact component={TuitionList}/>
            <Route path="/enrollment/tuition/:id/create" exact component={AddTuition}/>
            <Route path="/enrollment/tuition-request/:id/create-new" exact component={CreateTuition}/>
            <Route path="/tuition/update-tuition" exact component={UpdateTuition}/>
            <Route path="/enrollment/tuition-request/:status/:id/:active_tab?" exact component={TuitionRequestDashboard}/>
            <Route path="/enrollment/course-list" exact component={CourseList} />
            <Route path="/enrollments/tuition" exact component={Tuition} />
            <Route path="/enrollment/demo-class" exact component={DemoClass} />
            <Route path="/enrollments/add-course" exact component={AddCourse2}/>
            <Route path="/enrollments/update-course" exact component={UpdateCourse2}/>
            <Route path="/enrollments/add-demo-class" exact component={AddDemoClass}/>
            <Route path="/enrollments/update-demo-class" exact component={UpdateDemoClass} />

            {/* Transactions Routes */}
            <Route path="/transactions/:active_tab?" exact component={Transactions}/>
            <Route path="/transaction/addincome" exact component={AddIncome}/>
            <Route path="/transaction/editincome/:transactionID" exact component={EditIncome}/>
            <Route path="/transaction/addexpense" exact component={AddExpense}/>
            <Route path="/transaction/editexpense/:transactionID" exact  component={EditExpense}/>
            <Route path="/transaction-report" exact component={TransactionReport}/>

            {/* Cluster Routes */}
            <Route path="/cluster/:active_tab?" exact component={Cluster}/>
            <Route path="/clusters/add" exact component={AddCluster} />
            <Route path="/clusters/:clusterId/:active_tab?" exact component={ClusterDashboard} />
            <Route path="/Cluster/:clusterId/batches/:batchesId/details" exact component={ClusterBatchesDetails} />
            <Route path="/Cluster/:clusterId/timetable/:timetableId/details" exact component={ClusterTimetableDetails} />
            <Route path="/Cluster/:clusterId/package/:packageId/details" exact component={ClusterPackageDetails} />

            {/* Master Cluster Routes */}
            <Route path="/master-cluster/:active_tab?" exact component={MasterCluster} />
            <Route path="/master-clusters/add" exact component={AddMasterCluster} />
            <Route path="/master-cluster/:teacherId/:masterClusterId/:active_tab?" exact component={MasterClusterDashboard} />
            <Route path="/master-cluster/:masterClusterId/batches/:batchesId/:teacherId/details" exact component={MasterClusterBatchesDetails} />
            <Route path="/master-cluster/:masterClusterId/timetable/:timetableId/:teacherId/details" exact component={MasterClusterTimetableDetails} />
            <Route path="/master-cluster/:masterClusterId/package/:packageId/:teacherId/details" exact component={MasterClusterPackageDetails} />

            {/* Users Routes */}
            <Route path="/users" exact component={Users}/>

            {/* Notifications Routes */}
            <Route path="/notifications" exact component={Notifications}/>

            {/* Referral */}
            <Route path="/referral" exact component={Referral} />
            
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
